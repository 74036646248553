// TYPES

export type Days =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type OperatingHoursObject = {
  [key in Days]: {
    opening_time: string;
    closing_time: string;
  } | null;
};

export type MediaObject = {
  id: string;
  url: string;
};

export type Icon = {
  id: string;
  object: string;
  svg_base64: string;
};

export type LanguageObject<T extends string | string[] = string> = {
  lang: string;
  text: T;
};

type LinkObject = {
  type: string;
  url: string;
};

type LatLng = {
  latitude: number;
  longitude: number;
};

export type FloorLevels = {
  [key: string]: {
    floor: string;
    id: number;
    name: LanguageObject[];
    short_name: string;
  };
};

type Stylesheet = {
  id: string;
  name: LanguageObject[];
  url: string;
};

export type Project = {
  id: string;
  name: string;
  defaultLanguage: string;
};

export type SearchedFeatureData = {
  id: number;
  type: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
  layer: {
    id: string;
    type: string;
  };
  source: string;
  sourceLayer: string;
  properties: {
    lm_id: string;
    floor_id: number;
    name: LanguageObject[];
    popup_header: LanguageObject[];
    street_address: string | null;
    popup_image_url: string | null;
    category: string;
  };
};

export type JobStatus = "PENDING" | "RUNNING" | "FAILED" | "COMPLETED";

// INTERFACES

interface CategoryBase {
  id: string;
  object: string;
  name: LanguageObject[];
}

export interface SubcategoryObject extends CategoryBase {
  metatags: string[] | null;
}

export interface CategoryObject extends CategoryBase {
  subcategories: SubcategoryObject[];
}

export enum STATUS {
  PUBLISHED = "published",
  DRAFT = "draft",
  DRAFT_ARCHIVED = "draft-archived",
  ARCHIVED = "archived",
  DRAFT_RESTORED = "draft-restored",
}

export interface FeatureData {
  id: string;
  uid: number;
  object: string;
  status: STATUS;
  type: string;
  is_temporarily_closed: boolean;
  last_modified: {
    modified_at: string;
    modified_by: string;
  };
  label: {
    name: LanguageObject[] | null;
    reference: LanguageObject[] | null;
    icon: string | null;
    is_visible: boolean;
  };
  categories: {
    category: {
      id: string;
      name: LanguageObject[];
    };
    subcategory: {
      id: string;
      name: LanguageObject[];
    };
  };
  rank: {
    minimum: number;
    maximum: number;
    default: number;
    value: number;
  };
  street_address: string;
  location: {
    floor_level: string | null;
    center: {
      latitude: number;
      longitude: number;
    };
  };
  information: {
    long_name: LanguageObject[] | null;
    summary: LanguageObject[] | null;
    description: LanguageObject[] | null;
    links: LinkObject[];
    phone_number: string;
    operating_hours: OperatingHoursObject;
  };
  media: MediaObject[] | null;
  tags: LanguageObject<string[]>[];
}

interface MapData {
  id: string;
  object: string;
  access_token: string;
  bearing: number;
  description: LanguageObject[];
  extents: {
    bottom_left: LatLng;
    top_right: LatLng;
  };
  center: {
    latitude: number;
    longitude: number;
  };
  floors: {
    available: FloorLevels;
    default: string;
  };
  languages: {
    available: Language[];
    default: Language;
  };
  name: LanguageObject[];
  pitch: number;
  stylesheets: {
    available: Stylesheet[];
    default: string;
  };
  timezone: string;
  zoom: {
    minimum: number;
    maximum: number;
    default: number;
  };
}
export interface JobRun {
  job_name: string;
  run_id: string;
  status: JobStatus;
  creation_time: string;
  start_time: string | null;
  end_time: string | null;
}

interface ProjectJob {
  job_name: string;
  previous_run: JobRun;
  current_run: JobRun;
  ui: {
    title: string;
    summary: string;
    confirmation: {
      title: string;
      summary: string;
      accept_label: string;
    };
  };
}

export interface ProjectLinkData {
  id: number;
  object: string;
  name: string;
  full_url: string;
  created_at: string;
  last_modified: string;
  metadata: {
    [key: string]: string;
  } | null;
  project: string;
  real_url: string;
  scan_code: string;
  shortlink_url: string | null;
  qr_code: {
    svg_base64: string;
    png_base64: string;
  };
}

interface BaseResponseObject {
  object: string;
  url: string;
}

export interface MapDataResponse extends BaseResponseObject {
  data: MapData[];
}

export interface FeatureDataResponse extends BaseResponseObject {
  data: FeatureData[];
}

export interface SingleFeatureDataResponse extends BaseResponseObject {
  data: FeatureData;
}

export interface CategoryDataResponse extends BaseResponseObject {
  data: CategoryObject[];
}

export interface MMSStatusResponse {
  unpublished_features: number;
  deleted_features: number;
}

export interface SingleMapDataResponse extends MapData, BaseResponseObject {}

export interface SearchedFeatureDataResponse extends BaseResponseObject {
  data: SearchedFeatureData[];
}

export interface ProjectJobsResponse extends BaseResponseObject {
  data: ProjectJob[];
}

export interface RunProjectJobResponse {
  run_id: string;
}

export interface ProjectLinksResponse extends BaseResponseObject {
  has_more: boolean;
  data: ProjectLinkData[];
}

export interface LinkCreatedResponse {
  type: string;
  code: number;
  message: string;
}

export type CreateLinkRequest = Pick<
  ProjectLinkData,
  "real_url" | "shortlink_url" | "name" | "metadata" | "project" | "scan_code"
>;

export interface LinkUpdateRequest {
  real_url?: string;
  shortlink_url?: string | null;
  name?: string;
  metadata?: {
    [key: string]: string;
  } | null;
  scan_code?: string;
}

export interface LinkUpdateResponse {
  type: string;
  code: number;
  message: string;
}

export interface Language {
  code: string;
  human_readable_language: string;
  human_readable_region: string;
}
